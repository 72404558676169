import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/public/images/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(routes)/[...notFound]/components/NotFoundButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(routes)/[...notFound]/components/NotFoundText.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/components/Header/Announcement/DiscountAnnouncement.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/components/Header/HeaderAnnouncement.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/providers/TranslationProvider.tsx");
