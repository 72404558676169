import Translations from "./translations";

export enum EventType {
    NEW_YEAR,
    VALENTINE,
    APRIL_FOOLS,
    EASTER,
    SUMMER,
    HALLOWEEN,
    BLACK_FRIDAY,
    WINTER,
    CHRISTMAS,
};

interface DateRange {
    startDate: Date;
    endDate: Date;
}

export interface Event extends DateRange {
    type: EventType;
    name: string;
    discount: number;
};

function resolveBlackFridayDate(): DateRange {
    const year: number = new Date().getFullYear();
    const firstNovember: Date = new Date(year, 10, 1);
    const dayOfWeek: number = firstNovember.getDay();
    const fourthThursday: number = 1 + ((11 - dayOfWeek) % 7) + 21;
    const startDate: Date = new Date(year, 10, fourthThursday);
    startDate.setDate(startDate.getDate() + 1);
    const endDate: Date = new Date(startDate);
    endDate.setDate(endDate.getDate() + 4);
    return { startDate, endDate };
}

export const EVENTS: Event[] = [
    {
        type: EventType.NEW_YEAR,
        name: `${Translations.DISCOUNT}:new-year`,
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(new Date().getFullYear(), 0, 1),
        discount: 25,
    },
    {
        type: EventType.VALENTINE,
        name: `${Translations.DISCOUNT}:valentine`,
        startDate: new Date(new Date().getFullYear(), 1, 13),
        endDate: new Date(new Date().getFullYear(), 1, 15),
        discount: 75,
    },
    {
        type: EventType.APRIL_FOOLS,
        name: `${Translations.DISCOUNT}:april-fools`,
        startDate: new Date(new Date().getFullYear(), 3, 1),
        endDate: new Date(new Date().getFullYear(), 3, 1),
        discount: 20,
    },
    {
        type: EventType.EASTER,
        name: `${Translations.DISCOUNT}:easter`,
        startDate: new Date(new Date().getFullYear(), 3, 2),
        endDate: new Date(new Date().getFullYear(), 3, 30),
        discount: 10,
    },
    {
        type: EventType.SUMMER,
        name: `${Translations.DISCOUNT}:summer`,
        startDate: new Date(new Date().getFullYear(), 5, 1),
        endDate: new Date(new Date().getFullYear(), 7, 31),
        discount: 15,
    },
    {
        type: EventType.HALLOWEEN,
        name: `${Translations.DISCOUNT}:halloween`,
        startDate: new Date(new Date().getFullYear(), 9, 31),
        endDate: new Date(new Date().getFullYear(), 9, 31),
        discount: 25,
    },
    {
        type: EventType.BLACK_FRIDAY,
        name: `${Translations.DISCOUNT}:black-friday`,
        ...resolveBlackFridayDate(),
        discount: 50,
    },
    {
        type: EventType.WINTER,
        name: `${Translations.DISCOUNT}:winter`,
        startDate: new Date(new Date().getFullYear(), 11, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31),
        discount: 10,
    },
    {
        type: EventType.CHRISTMAS,
        name: `${Translations.DISCOUNT}:christmas`,
        startDate: new Date(new Date().getFullYear(), 11, 25),
        endDate: new Date(new Date().getFullYear(), 11, 25),
        discount: 75,
    }
];
